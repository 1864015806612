@font-face {
  font-family: 'Colus';
  src: url('Colus-Regular.eot');
  src: url('Colus-Regular.eot?#iefix') format('embedded-opentype'),
    url('Colus-Regular.woff2') format('woff2'),
    url('Colus-Regular.woff') format('woff'),
    url('Colus-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Candara';
  src: url('Candara.eot');
  src: url('Candara.eot?#iefix') format('embedded-opentype'),
    url('Candara.woff2') format('woff2'), url('Candara.woff') format('woff'),
    url('Candara.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Matricha';
  src: url('Matricha.ttf');
  src: url('Matricha.ttf?#iefix') format('embedded-opentype'),
    url('Matricha.ttf') format('ttf'), url('Matricha.ttf') format('ttf'),
    url('Matricha.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Epson';
  src: url('Epson.ttf');
  src: url('Epson.ttf?#iefix') format('embedded-opentype'),
    url('Epson.ttf') format('ttf'), url('Epson.ttf') format('ttf'),
    url('Epson.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safariinput label {
      translate: transform(14px, 12px);
    }
  }
}
