@font-face {
  font-family: 'Colus';
  src: url(/static/media/Colus-Regular.f29eb307.eot);
  src: url(/static/media/Colus-Regular.f29eb307.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Colus-Regular.1444821b.woff2) format('woff2'),
    url(/static/media/Colus-Regular.dc9ad59e.woff) format('woff'),
    url(/static/media/Colus-Regular.c3cc96a7.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Candara';
  src: url(/static/media/Candara.c9c02a70.eot);
  src: url(/static/media/Candara.c9c02a70.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Candara.2676ea4f.woff2) format('woff2'), url(/static/media/Candara.ffbf369f.woff) format('woff'),
    url(/static/media/Candara.6e02ea51.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Matricha';
  src: url(/static/media/Matricha.daad3e90.ttf);
  src: url(/static/media/Matricha.daad3e90.ttf?#iefix) format('embedded-opentype'),
    url(/static/media/Matricha.daad3e90.ttf) format('ttf'), url(/static/media/Matricha.daad3e90.ttf) format('ttf'),
    url(/static/media/Matricha.daad3e90.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Epson';
  src: url(/static/media/Epson.80a7e60b.ttf);
  src: url(/static/media/Epson.80a7e60b.ttf?#iefix) format('embedded-opentype'),
    url(/static/media/Epson.80a7e60b.ttf) format('ttf'), url(/static/media/Epson.80a7e60b.ttf) format('ttf'),
    url(/static/media/Epson.80a7e60b.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safariinput label {
      translate: transform(14px, 12px);
    }
  }
}

